import geojsonBounds from "geojson-bounds"

const getBounds = (tracks, points) => {
    const collection = {
        type: "FeatureCollection",
        features: [
            ...points.map(p => ({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: p.coordinates,
                }
            })),
            ...tracks.map(t => ({
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: t.coordinates,
                }
            })),
        ]
    }
    return geojsonBounds.envelope(collection)
}

export { getBounds }