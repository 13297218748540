import { icon } from "leaflet"
import shadowImage from '../../images/markers/marker-shadow.png'

const colors = [
    "blue",
    "red",
]

const icons = colors.reduce((obj, c) => {
    if (typeof window !== "undefined") {
        return {
            ...obj,
            [c]: icon({
                iconUrl: require(`../../images/markers/marker-icon-${c}.png`).default,
                iconRetinaUrl: require(`../../images/markers/marker-icon-${c}.png`).default,
                shadowUrl: shadowImage,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
            })
        }
    } else {
        return {...obj, [c]: c}
    }
}, {})

export default icons